
























































































































































































































































































































































































































































































































































































































































































.while_printing_the_report {
  .el-checkbox:last-of-type {
    margin-bottom: 0;
  }
  @media print {
    .el-checkbox:last-of-type {
      margin-bottom: 0;
    }
  }
}
